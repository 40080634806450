<template>
  <div class="main" data-title="战败分析" v-title>
    <van-cell-group title=" ">
      <van-panel title="销售顾问-接待单个客户平均时长（分钟）">
        <div class="chart-content">
          <ve-histogram :data="chartData.chart1"></ve-histogram>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="战败原因统计">
        <div class="chart-content">
          <ve-histogram :data="chartData.chart2"></ve-histogram>
        </div>
      </van-panel>
    </van-cell-group>

    <!-- <van-popup position="bottom" v-model="showSearch">
      <select-dep @setDeps="setDeps" ref="selectDep"></select-dep>
      <el-date-picker
        :clearable="false"
        @change="getNewData"
        end-placeholder="结束月份"
        format="yyyy年MM月"
        range-separator="-"
        start-placeholder="开始月份"
        style="margin-left:20px"
        type="monthrange"
        v-model="key"
        value-format="yyyy-MM"
      ></el-date-picker>
    </van-popup>-->
    <chart-search></chart-search>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import VeHistogram from 'v-charts/lib/histogram.common'
import ChartSearch from '@/components/chart/ChartSearch.vue'
export default {
  components: {
    // SelectDep,
    VeHistogram,
    ChartSearch
  },
  data() {
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: [],
      isData: false,

      chartData: {}
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    setDeps(arr) {
      this.dept_ids = arr
      this.getListData()
    },
    getNewData() {
      this.getListData()
    },
    getListData() {
      this.$axios
        .post('/statistic/chart3', {
          begin_month: this.key[0],
          end_month: this.key[1],
          dept_ids: this.dept_ids
        })
        .then(res => {
          if (res.data.code == 200) {
            this.isData = true
            this.chartData = res.data.data.list
          } else {
            this.isData = false
          }
        })
    },

    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  .table {
    border-collapse: collapse;
    th,
    td {
      background: #f5f7fa;
      color: rgba(0, 0, 0, 0.85);
      border: 1px solid #ebeef5;
      padding: 7px 10px;
      font-size: 12px;
      word-break: keep-all;
      white-space: nowrap;
      text-align: center;
      &:first-child {
        border-left: none;
      }
    }

    td {
      background: #fff;
      color: #606266;
    }
  }
}
.chart-content {
  padding: 10px 20px;
}
</style>